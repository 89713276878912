// XD デザイン基準
const currentWidth = 1600;
const currentHeight = 900;

function fillDiv() {
    // <div id="fill">対象のDOM</div>
    var div = window.fill;

    var availableHeight = window.innerHeight;
    var availableWidth = window.innerWidth;

    var scaleX = availableWidth / currentWidth;
    var scaleY = availableHeight / currentHeight;

    scaleX = Math.min(scaleX, scaleY);
    scaleY = scaleX;

    var translationX = Math.round((availableWidth - (currentWidth * scaleX)) / 2);
    var translationY = Math.round((availableHeight - (currentHeight * scaleY)) / 2);

    if (div.style) {
        div.style.position = "fixed";
        div.style.left = "0px";
        div.style.top = "0px";
        div.style.webkitTransform = "translate(" + translationX + "px, " + translationY + "px) scale3d(" + scaleX + ", " + scaleY + ", 1)";
        div.style.webkitTransformOrigin = "0 0";
    }
}

function calcSideNav() {
    const sideWidth = 291;
    var side = window.side;
    // var side = document.getElementsByClassName('sidenav');
    var availableWidth = window.innerWidth;
    // var scaleX = availableWidth / currentWidth;
    console.log(availableWidth);
    // side.style.left = "0px";
}